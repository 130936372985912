import React from "react"
import { clientOnboardingPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const ClientOnboarding = () => {
  return (
    <Layout>
      <Seo
        title="Streamline Client Onboarding for Accountants with Synkli"
        description={`Enhance your accounting practice with Synkli. Streamline client onboarding, gather crucial details, and customise the experience to meet your accounting practice management.`}
      />

      <FeatureDetailsPage data={clientOnboardingPage} />
      
    </Layout>
  )
}

export default ClientOnboarding
